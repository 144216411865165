*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    height: 100vh;
    font-family: 'Poppins';
    background: rgb(24, 24, 24);
}

body {
    width: 100%;
    max-width: 100%;

    overflow-x: hidden;
}

.webgl
{
    position: absolute;
    top: 0;
    left: 0;
    outline: none;

    mix-blend-mode: exclusion;
}


.container {
    height: 100vh;
    display: grid;
    place-items: center;
}

h1 {
    font-size: 5rem;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
}

section {
    height: 100vh;
    display: grid;
    place-items: center;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    text-size: 2rem
}